<template>
  <div>
  <calc-page>
    <template slot="topBox">
      <round-container>
        <div class="item-box">
          <van-cell>
            <div class="row-box">
              <title-item text="死亡时间"></title-item>
              <year-month-input
                class="date-input"
                idx="0"
                v-model="dieDate"
              ></year-month-input>
            </div>
          </van-cell>
        </div>
      </round-container>
      <round-add-container
        title="添加缴费记录"
        :list="feeList"
        @add="onAdd"
      >
        <annuity-year-list
          :list="feeList"
          @itemClick="onUpdate"
        ></annuity-year-list>
      </round-add-container>
    </template>

    <template slot="footerBtn">
      <div
        v-bind:class="['btn-box', 'btn-bk']"
        @click="onSubmit"
      >
        开始计算
      </div>
    </template>
  </calc-page>
  <on-job-die-dialog ref="overlay"></on-job-die-dialog>
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Field, NumberKeyboard,Dialog } from "vant";
import YearMonthInput from "@/components/YearMonthInput";
import RoundContainer from "@/views/calc/components/RoundContainer";
import RoundAddContainer from "@/views/calc/components/RoundAddContainer.vue";
import AnnuityYearList from "@/views/calc/annuity/components/AnnuityYearList.vue";
import TitleItem from "@/views/calc/TitleItem";
import CalcPage from "@/components/CalcPage";
import { mapGetters, mapMutations } from "vuex";
import to from "@/utils/to";
import { calcAnnuityReturnOnJobDie } from "@/api/tool";
import OnJobDieDialog from "./OnJobDieDialog"

export default {
  components: {
    CalcPage,
    TitleItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [NumberKeyboard.name]: NumberKeyboard,
    [Divider.name]: Divider,
    YearMonthInput,
    RoundContainer,
    RoundAddContainer,
    AnnuityYearList,
    OnJobDieDialog,
  },
  computed: {
    dieDate: {
      get() {
        return this.getAnnuityReturnOnJobDie().dieDate;
      },
      set(val) {
        this.setAnnuityReturnOnJobDie({ dieDate: val });
      },
    },
    feeList() {
      return this.getAnnuityReturnOnJobDie().feeList;
    },
    btnDisabled() {
      if (this.dieDate != "" && this.feeList.length > 0) {
        return false;
      }

      return true;
    },
  },
  methods: {
    onAdd() {
      this.$refs.overlay.open();
    },
    onUpdate(item) {
      this.$refs.overlay.open(item);
    },
    async onSubmit() {
      const itemDie = new Date(this.dieDate);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth()+1;
      if (itemDie > new Date()) {
        Dialog.alert({
          title: "死亡日期不正确",
          message: "死亡日期不能大于当前年月：" + currentYear + "/" + currentMonth + "，请重新输入"
        });
        return;
      }

      if (this.btnDisabled) {
        this.$toast.fail("请先填写相关信息");
        return;
      }

      this.$showLoading("加载中...");
      const data = {
        feeList: this.feeList,
        dieDate: this.dieDate + '/01',
       
      }
      
      let [err, res] = await to(calcAnnuityReturnOnJobDie(data));
      this.$hideLoading();
      if (err) {
        this.$toast.fail("获取失败");
        return;
      }
      this.$router.push({
        name: "AnnuityReturnOnJobDieResPage",
        query: { res: JSON.stringify(res) },
      });
    },
    ...mapGetters("calc", ["getAnnuityReturnOnJobDie"]),
    ...mapMutations("calc", ["setAnnuityReturnOnJobDie"]),
  },
};
</script>

<style scoped>
.page >>> .van-divider {
  margin: 0;
}

.item-box {
  padding: 8px 0;
}

.item-box >>> .van-cell {
  padding: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.row-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row-box >>> .TitleItem__title--2DuKR {
  width: 150px;
}

.row-box >>> .van-field__control {
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.row-box span {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.row-box .unit {
  font-size: 14px;
  font-weight: normal;
  position: relative;
  top: 1px;
}

.row-box >>> .van-cell::after {
  border: none;
}

.btn-box {
  position: fixed;
  bottom: 10px;
  background: #cccccc;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  padding: 10px 130px;
}

.btn-bk {
  background:#658CF1;
}

.date-input {
  margin-right: 4px;
}
</style>