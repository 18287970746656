<template>
  <input-field
    :tabindex="idx"
    outline="0"
    hidefocus="true"
    :name="name"
    :disabled="disabled"
    :tip="tip"
    @tip="$emit('tip')"
    @blur="showKeyboard = false"
  >
    <div @click="!disabled && (showKeyboard = true)">
      <span>{{ value }}</span>
      <span class="unit" :style="{ color: color }">{{ unit }}</span>
    </div>
    <van-number-keyboard
      v-model="data"
      :maxlength="limit"
      :show="showKeyboard"
      @blur="showKeyboard = false"
    />
  </input-field>
</template>

<script>
import { NumberKeyboard } from "vant";
import InputField from "./InputField";

export default {
  props: {
    name: {
      type: String,
    },
    value: {
      type: String,
    },
    unit: {
      type: String,
      default: "元",
    },
    tip: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 6,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    InputField,
    [NumberKeyboard.name]: NumberKeyboard,
  },
  computed: {
    color() {
      return this.disabled ? '#E6E6E6' : '#808080'
    }
  },
  data() {
    return {
      idx: new Date().getTime(),
      showKeyboard: false,
      data: this.value,
    };
  },
  watch: {
    value() {
      this.data = this.value
    },
    data() {
      this.$emit("input", this.data);
    },
  }
};
</script>

<style scoped>
/* .field-box >>> .van-divider {
  margin: 0;
}

.field {
  display: flex;
  align-items: center;
  height: 60px;
}

.name {
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #333333;
  display: flex;
  align-items: center;
}

.tip {
  width: 14px;
  height: 14px;
}

.data {
  flex: 1;
  text-align: right;
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  color: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}*/

.unit {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #999999;
  margin-right: 12px;
}
</style>