<template>
  <div>
    <div class="title">
      <div class="year-box">缴费年度</div>
      <div class="payment-box">
        年缴费基数和<img
          @click.stop="onToast('payment')"
          class="icon-size"
          src="@/assets/imgs/calc/link.png"
        />
      </div>
      <div class="month-box">缴费月数</div>
    </div>
    <div
      v-for="(item, idx) in list"
      :key="idx"
      @click="$emit('itemClick', item)"
    >
      <div class="item-box">
        <div class="year-box">
          <a>{{ item.year }}</a>
        </div>
        <div class="payment-box">
          <a>{{ item.payment }}</a
          ><span>元</span>
        </div>
        <div class="month-box">
          <a>{{ item.month }}</a
          ><span>月</span>
        </div>
      </div>
      <van-divider />
    </div>
  </div>
</template>

<script>
import { Divider } from "vant";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    [Divider.name]: Divider,
  },
  methods: {
    onToast(type) {
      if ("payment" === type) {
        this.$toast("当年所有缴费月基数相加");
      }
    },
    // sortByKey(array, key) {
    //   return array.sort(function (a, b) {
    //     let x = a[key];
    //     let y = b[key];
    //     return x < y ? -1 : x > y ? 1 : 0;
    //   });
    // },
  },
};
</script>

<style scoped>
.title {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}

.year-box {
  width: 100px;
  display: flex;
}

.payment-box {
  width: 150px;
  display: flex;
  justify-content: center;
}

.month-box {
  width: 100px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.icon-size {
  width: 18px;
  height: 18px;
}

.item-box {
  display: flex;
  align-items: center;
  padding-right: 10px;
  line-height: 33px;
}

.item-box a {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.item-box span {
  font-size: 14px;
  color: #b2b2b2;
  position: relative;
  top: 3px;
}
</style>