<template>
  <div>
    <number-input-field class="font-margin"
      name="缴费年度"
      unit="年"
      v-model="obj.year"
    ></number-input-field>

    <number-input-field class="font-margin"
      name="年缴费基数和"
      :tip="true"
      @tip="$toast('当年所有缴费月基数相加')"
      v-model="obj.payment"
    ></number-input-field>

    <number-input-field class="font-margin"
      name="缴费月数"
      unit="月"
      v-model="obj.month"
    ></number-input-field>
  </div>
</template>

<script>
import NumberInputField from "@/views/calc/components/NumberInputField";

export default {
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    NumberInputField,
  },
  data() {
    return {
      yearShow: false,
      paymentShow: false,
      monthShow: false,
      obj: {},
    };
  },
  watch: {
    data(val) {
      console.log("watch", val);
      this.init(val);
    },
  },
  mounted() {
    this.init(this.data);
  },
  methods: {
    init(data) {
      this.obj = data != null ? data : { year: "", payment: "", month: "" };
    },
    getValue() {
      const { idx } = this.obj;
      if (idx === undefined) {
        this.obj.idx = new Date().getTime();
      }

      return this.obj;
    },
  },
};
</script>

<style scoped>
.font-margin {
  margin-left: 12px;
}

</style>