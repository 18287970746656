<template>
    <overlay-dialog
      @onBack="isShow = false"
      @onFinish="onFinish"
      @onDel="onDel"
      @onUpdate="onUpdate"
      :isUpdate="isUpdate"
      :isShow="isShow"
    >
      <template slot="topBox">
        <annuity-year-editor ref="editor" :data="data"></annuity-year-editor>
      </template>
    </overlay-dialog>
</template>

<script>
import { Dialog } from "vant";
import OverlayDialog from "@/views/calc/components/OverlayDialog";
import AnnuityYearEditor from "@/views/calc/annuity/components/AnnuityYearEditor.vue";
import { mapMutations, mapGetters } from "vuex";
export default {
  components: {
    OverlayDialog,
    AnnuityYearEditor,
  },
  data() {
    return {
      data: null,
      isShow: false,
      isUpdate: false,
    };
  },
  mounted() {
    const { item, isUpdate } = this.$route.query;
    if (isUpdate != undefined) {
      this.isUpdate = true;
      this.data = JSON.parse(item);
      console.log(this.data);
    }
  },
  computed: {
    dieDate: {
      get() {
        return this.getAnnuityReturnOnJobDie().dieDate;
      },
      set(val) {
        this.setAnnuityReturnOnJobDie({ dieDate: val });
      },
    },
    list: {
      get() {
        return this.getAnnuityReturnOnJobDie().feeList;
      },
      set(val) {
        this.setAnnuityReturnOnJobDie({ feeList: val });
      },
    },
  },
  methods: {
    open(item) {
      this.isUpdate = item !== undefined ? true : false;
      if (!this.isUpdate) {
        this.data = {
          idx: new Date().getTime(),
          year: "",
          payment: "",
          month: "",
        }
      } else {
        this.data = item;
      }
      this.isShow = true;
    },
    onDel() {
      this.data = this.$refs.editor.getValue();
      const index = this.list.findIndex((item) => {
        if (item.idx === this.data.idx) {
          return true;
        }
      });

      this.list.splice(index, 1);
      this.list = this.list;

      // this.$router.go(-1);
      this.isShow = false;
    },
    onUpdate() {
      this.data = this.$refs.editor.getValue();
      for (let i = 0; i < this.list.length; i++) {
        if (
          this.data.year == this.list[i].year &&
          this.data.idx !== this.list[i].idx
        ) {
          Dialog.alert({
            title: "缴费年度重合",
            message:
              "输入的缴费年度，和已经存在的缴费年度" +
              this.list[i].year +
              "重合, 请重新输入",
          });
          return;
        }
      }
      if (this.data.month > 12) {
        Dialog.alert({
          title: "缴费月数不正确",
          message: "输入的月数大于12",
        });
        return;
      }

      this.list.findIndex((item) => {
        if (
          item.idx === this.data.idx &&
          this.dieDate.substring(0, 4) >= this.data.year
        ) {
          item.year = this.data.year;
          item.payment = this.data.payment;
          item.month = this.data.month;
          return true;
        } else {
          this.$toast.fail("缴费年度不能大于死亡年度，请重新修改");
        }
      });

      this.list = this.list;
      // this.$router.go(-1);
      this.isShow = false;
    },
    onFinish() {
      this.data = this.$refs.editor.getValue();
      const { year, payment, month } = this.data;

      if (year == "") {
        this.$toast.fail("请填写缴费年度");
        return;
      }

      if (payment == "") {
        this.$toast.fail("请填写年缴费基数和");
        return;
      }

      if (month == "") {
        this.$toast.fail("请填写年缴费月数");
        return;
      }

      if (month > 12) {
        Dialog.alert({
          title: "缴费月数不正确",
          message: "输入的月数大于12",
        });
        return;
      }

      for (let i = 0; i < this.list.length; i++) {
        if (year == this.list[i].year) {
          Dialog.alert({
            title: "缴费年度重合",
            message:
              "输入的缴费年度，和已经存在的缴费年度" +
              this.list[i].year +
              "重合, 请重新输入",
          });
          return;
        }
      }

      if (this.dieDate.substring(0, 4) >= this.data.year) {
        this.list.push(this.data);
      } else {
        this.$toast.fail("缴费年度不能大于死亡年度，请重新输入");
        return;
      }
      // this.list.push(this.data);
      this.list = this.list;
      // this.$router.go(-1);
      this.isShow = false;
    },
    ...mapGetters("calc", ["getAnnuityReturnOnJobDie"]),
    ...mapMutations("calc", ["setAnnuityReturnOnJobDie"]),
  },
};
</script>

<style scoped>
.row-box {
  display: flex;
  justify-content: space-between;
}

.row-box >>> .TitleItem__title--2DuKR {
  width: 150px;
}

.row-box >>> .van-field__control {
  width: 200px;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.row-box span {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.action-box {
  padding: 20px 6px 20px 36px;
  display: flex;
  flex-wrap: wrap;
}

.action-item__box {
  width: 88px;
  height: 38px;
  border: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 28px 0;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #333333;
}

.clickBk {
  background: #658CF1;
  color: #ffffff;
}
</style>